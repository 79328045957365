import getImage, { isImageBucket, isPublicAsset, sizes, IMG_API } from "./getImage";

export default function getSrcset(src, size){
  let formats = {
    jpeg: null,
    png: null,
    webp: null,
    src,
  };
  if(!src){
    return {};
  }
  if ((isImageBucket(src) || isPublicAsset(src)) && size in sizes) {
    const optimized = getImage(src, size);
    if(!optimized){
      return {};
    }
    formats.src = optimized;
    const parts = optimized.toLowerCase().match(/\.\w+$/);
    const ext = parts && parts.length > 0 ? parts[parts.length - 1] : "";

    if (ext === ".jpg" || ext === ".jpeg") {
      formats.jpeg = optimized;
    } else if (ext === ".png") {
      formats.png = optimized;
    }
    formats.webp = `${IMG_API}/resize?nocrop=true&type=webp&width=${sizes[size]}&url=${src}`;
  }
  return formats;
}  
