const tags = (string = "") => {
  const matches = string.match(/\[([^\]]+\])/g);
  if (matches && matches.length > 0) {
    return matches.map(str => str.replace(/[\[\]\\\"]/g, ""));
  }
  return [];
};

const params = (string = "") => {
  const chunks = string.split(/\s+/);
  const [name, ...attrs] = chunks;
  const kv = attrs.map(part => part.split("=")).reduce((acc, kv) => {
    if (kv.length == 2) {
      return Object.assign(acc, { [kv[0]]: kv[1] });
    }
    return acc;
  }, {});
  return { [name]: kv };
};

const parse = (string = "") => {
  return tags(string)
    .map(params)
    .reduce((acc, param) => {
      const key = Object.keys(param)[0];
      return Object.assign(acc, { [key]: param[key] });
    }, {});
};

const strip = (string = "") => string.replace(/\[([^\]]+\])/g, "");

export default {
  parse,
  strip
};
