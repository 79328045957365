import io from "socket.io-client";
import { forEach } from "lodash";
import { Actions } from "@voxmarkets/vox-reducers";

class RealtimeData {
  static instance = null;
  static store = null;

  static getInstance() {
    if (this.instance === null) {
      this.instance = new RealtimeData(this.store, {
        host: "https://realtime.voxmarkets.co.uk",
        transports: ["polling"]
      });
    }
    return this.instance;
  }

  static initStore(store){ this.store = store }

  constructor(store, options) {
    // we need the store for dispatching updates later
    this.store = store;

    // create a collection that will hold the listingIds we want to hear about
    this.listingIdCollection = {};

    // default the next reconnection attempt
    this.reconnectTime = 2000;

    // default the stored jwt
    this.jwt = null;

    // create the socket instance
    this.socketInstance = io(options.host);

    // listen for generic messages
    this.socketInstance.on("message", data => {
      console.log(data);
    });

    this.socketInstance.on("connect", data => {
      // do we have a jwt?
      if (this.jwt) {
        // re-authenticate
        this.socketInstance.emit("authenticate", { jwt: this.jwt });
      } else {
        // just resubscribe to any requested listings
        this.resubscribe();
      }
    });

    this.socketInstance.on("reconnect", data => {
      console.log("Reconnected");
    });

    this.socketInstance.on("resubscribe", data => {
      console.log("Resubscribe");
      // resubscribe to any requested listings - called after a change in authentication
      this.resubscribe();
    });

    this.socketInstance.on("disconnect", reason => {
      console.log("Disconnected: ", reason);
      if (reason === "io server disconnect") {
        this.reconnect();
      }
    });

    this.socketInstance.on("price", data => {
      // handle incoming price data, unpack the envelope first
      if (
        data !== undefined &&
        data !== null &&
        data.hasOwnProperty("listingId") &&
        data.hasOwnProperty("update")
      ) {
        this.store.dispatch(
          Actions.quotes.receiveInteractiveQuote(data.update)
        );
      }
    });
  }

  resubscribe() {
    // subscribe to each of the listings requested
    forEach(this.listingIdCollection, (info, listingId) => {
      this.socketInstance.emit("subscribePrices", { listingId: listingId });
    });
  }

  reconnect() {
    return setTimeout(() => {
      this.socketInstance.connect();
      this.reconnectTime = (this.reconnectTime + 5000) % 30000;
    }, this.reconnectTime);
  }

  setJwt(token) {
    // save the token
    this.jwt = token;
    // send the jwt to the socket server if we are connected
    if (this.socketInstance.connected) {
      this.socketInstance.emit("authenticate", { jwt: this.jwt });
    }
  }

  subscribeToPrices(listingId) {
    // get the descriptor for this listing, if any
    const { [listingId]: info = { count: 0 } } = this.listingIdCollection;

    // is this the first subscription?
    if (info.count === 0) {
      // yes, so - if we're connected...
      if (this.socketInstance.connected) {
        // subscribe to prices
        this.socketInstance.emit("subscribePrices", { listingId: listingId });
      }
    }
    // update the collection with another listener
    this.listingIdCollection = {
      ...this.listingIdCollection,
      [listingId]: { ...info, count: info.count + 1 }
    };
  }

  unsubscribeFromPrices(listingId) {
    // get the descriptor for this listing, if any
    const { [listingId]: info = { count: 0 } } = this.listingIdCollection;

    // is this the last unsubscribe from this listing?
    if (info.count === 1) {
      /// yes, so - if we're connected...
      if (this.socketInstance.connected) {
        // subscribe to prices
        this.socketInstance.emit("unsubscribePrices", { listingId: listingId });
      }
    }

    // update our count
    if (info.count > 1) {
      this.listingIdCollection = {
        ...this.listingIdCollection,
        [listingId]: { ...info, count: info.count - 1 }
      };
    } else if (info.count === 1) {
      // remove from the list entirely
      const {
        [listingId]: infoToRemove,
        ...otherInfo
      } = this.listingIdCollection;
      this.listingIdCollection = { ...otherInfo };
    }
  }
}

const mock = {
  getInstance() {
    return {
      subscribeToPrices: () => {},
      unsubscribeFromPrices: () => {},
    }
  },
  initStore(){}
}

export default mock;
