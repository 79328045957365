import jwtDecode from "jwt-decode";
import { Authentication } from "@voxmarkets/api-sdk";

if( typeof window !== "undefined"){
  window.dataLayer = window.dataLayer || [];
}

const voxTracker = (scope, payload, callback = () => {}) => {
  Authentication.getJWT().then(token => {
    const enhancedProps = {
      context: `${window.location.pathname}${window.location.search}`,
      userId: jwtDecode(token).id,
      ...payload
    };
    return fetch(`https://api.voxmarkets.co.uk/v2/statistics/track/${scope}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(enhancedProps)
    }).then(callback);
  });
};

export const track = (event, properties, callback) => {
  if( typeof window === "undefined"){
    return;
  }
  switch (event) {
    case "login":
      window.dataLayer.push({
        'userId' : properties.user_id
      })
    case "news":
      return voxTracker("news", properties, callback);
    case "media":
      return voxTracker("media", properties, callback);
    case "company":
      return voxTracker("company", properties);
    default:
      return ()=>{}
  }
};
