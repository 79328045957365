import Number from "./Number";

export default class DateFormatter {
  numberUtil = new Number();
  date = null;
  constructor(dateString, fixDate = false) {
    if (dateString && fixDate == true) {
      dateString = dateString.replace(/ /g, "T").replace(/\.000/g, "") + "Z";
    }
    this.date = new Date(dateString);
  }
  _months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  /**
   * Formats a date to a uniform style
   */
  formatDate = () => {
    const date = this._prefixZero(this.date.getDate());
    const month = this._months[this.date.getMonth()];
    const year = this._prefixZero(this.date.getFullYear());

    return `${month} ${date}, ${year}`;
  };

  formatTime = () => {
    const hours = this._prefixZero(this.date.getHours());
    const minutes = this._prefixZero(this.date.getMinutes());
    return `${hours}:${minutes}`;
  };

  _prefixZero = number => {
    return number < 10 ? `0${number}` : number;
  };
}
