export const sizes = {
  AVATAR: 64,
  THUMBNAIL: 320,
  PREVIEW: 640,
  LARGE: 1024,
  HD: 1920
};

const BUCKET = "vox.store.images";
const ASSETS = "vox.assets.public";
const IMG_SERVICE = "https://img.voxmarkets.co.uk";

export const IMG_API = "https://cached-api.voxmarkets.co.uk/img";

export const isImageBucket = url => url.includes(BUCKET);
export const isPublicAsset = url => url.includes(ASSETS);

const getFilePath = url => url.replace(new RegExp(`.+${BUCKET}/`), "");

const getImage = (original, size = "PREVIEW") => {
  if( !original ) return;
  if (!sizes.hasOwnProperty(size)) {
    console.warn(`image size ${size} not supported`);
    return original;
  }
  if (isImageBucket(original)) {
    const path = getFilePath(original);
    return `${IMG_SERVICE}/${sizes[size]}/${path}`;
  }
  else if(isPublicAsset(original)){
    return `${IMG_API}/resize?nocrop=true&width=${sizes[size]}&url=${original}`;
  }
  return original;
};

export default getImage;
