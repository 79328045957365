import React from "react";
export { formatLabel } from "./Text";

const prepend = prefix => str => prefix + str;
const mapProps = (props, which, fn) => {
  const mapped = {};
  which.forEach(prop => {
    if (prop in props) {
      mapped[prop] = fn(props[prop]);
    }
  });
  return Object.assign({}, props, mapped);
};

export const PrependBaseUrl = (base, whichProps, Component) => {
  const prefix = prepend(base);

  return props => {
    const prefixed = mapProps(props, whichProps, prefix);
    return <Component {...prefixed} />;
  };
};

export const AlignDecimals = ({ price }) => {
  const fixed = price.split(".");

  if (fixed.length == 0) {
    return <span>-</span>;
  }

  return (
    <span>
      {fixed[0]}
      <span style={{ minWidth: `4}rem`, textAlign: "left" }}>.{fixed[1]}</span>
    </span>
  );
};

export const DateFormat = ({ date, format }) => {
  var d = new Date(date);
  var yyyy = d.getFullYear();
  var mm = ("0" + (d.getMonth() + 1)).substr(-2);
  var dd = ("0" + d.getDate()).substr(-2);
  return [yyyy, mm, dd].join("-");
};

export const debounce = (func, delay) => {
  let inDebounce;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

export const isScrolledIntoView = el => {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  // Partially visible elements return true:
  return (
    typeof window !== "undefined" &&
    (elemTop < window.innerHeight && elemBottom >= 0)
  );
};

export const isTradingClose = phase =>
  phase === "CLOS" ||
  phase === "PosC" ||
  phase === "EoPC" ||
  phase === "CLOG" ||
  phase === "CPX";

/**
 * Returns number of deicmal places to display given a value
 * @param {number} number
 * @return {number} - number of decimal places
 */
export const getDecimalPlaces = number => {
  return number <= 0.1 ? 4 : number > 10 ? 2 : 3;
};

/**
 *
 * @param {number} number -
 * @param {number} decimalPlaces -
 * @return {string}
 */
export const formatNumber = (number, decimalPlaces) => {
  if (typeof number != "number" || isNaN(number)) return "-";
  try {
    decimalPlaces =
      decimalPlaces != undefined ? decimalPlaces : getDecimalPlaces(number);
    let whole = number
      .toString()
      .split(".")[0]
      .replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    if (decimalPlaces > 0) {
      return `${whole}.${number.toFixed(decimalPlaces).split(".")[1]}`;
    }
    return whole;
  } catch (e) {
    return "-";
  }
};

export const decodeHTML = str => {
  return typeof DOMParser !== "undefined" ? new DOMParser().parseFromString(str, "text/html").body.textContent : str;
};

export const groupBy = function(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const isArray = sample => Array.isArray(sample) && sample.length > 0;

export const mousePosition = (e, defaultRect) => {
  const container = e.currentTarget;
  const rect = defaultRect || container.getBoundingClientRect(),
    x = e.clientX - rect.left - container.clientLeft,
    y = e.clientY - rect.top - container.clientTop,
    xy = [x, y];
  return xy;
};

export function getScrollbarWidth() {
  var outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.width = "100px";
  outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

  document.body.appendChild(outer);

  var widthNoScroll = outer.offsetWidth;
  // force scrollbars
  outer.style.overflow = "scroll";

  // add innerdiv
  var inner = document.createElement("div");
  inner.style.width = "100%";
  outer.appendChild(inner);

  var widthWithScroll = inner.offsetWidth;

  // remove divs
  outer.parentNode.removeChild(outer);

  return widthNoScroll - widthWithScroll;
}

export const loadScript = (id, src) => {
  function getCurriedOnLoad(existingScript, callback) {
    const previousOnload = existingScript.onload || (() => {});
    return () => {
      previousOnload();
      callback();
    };
  }

  return new Promise((resolve, reject) => {
    const existingScript = document.getElementById(id);
    if (existingScript) {
      existingScript.onload = getCurriedOnLoad(existingScript, () => {
        resolve(src);
      });
      existingScript.onerror = e => reject(e);
    } else {
      const script = document.createElement("script");
      script.id = id;
      script.onload = () => {
        resolve(src);
      };
      script.onerror = e => reject(e);
      script.src = src;
      document.body.appendChild(script);
    }
  });
};

export const getScrollParent = node => {
  const regex = /(auto|scroll|hidden)/;
  const parents = (_node, ps) => {
    if (_node.parentNode === null) {
      return ps;
    }
    return parents(_node.parentNode, ps.concat([_node]));
  };

  const style = (_node, prop) =>
    getComputedStyle(_node, null).getPropertyValue(prop);
  const overflow = _node =>
    style(_node, "overflow") +
    style(_node, "overflow-y") +
    style(_node, "overflow-x");
  const scroll = _node => regex.test(overflow(_node));

  /* eslint-disable consistent-return */
  const scrollParent = _node => {
    if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
      return;
    }

    const ps = parents(_node.parentNode, []);

    for (let i = 0; i < ps.length; i += 1) {
      if (scroll(ps[i])) {
        return ps[i];
      }
    }

    return document.scrollingElement || document.documentElement;
  };

  return scrollParent(node);
  /* eslint-enable consistent-return */
};
